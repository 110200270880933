<template>
  <div class="about container">
    <div class="title-box">
      <a href="/"
        ><font-awesome-icon icon="fa-solid fa-chevron-left" size="lg" /> 홈</a
      >
    </div>
    <h2 class="page-title">캔디(CANDiY) 서비스</h2>
    <div style="height: 150px;margin-bottom: 10px;text-align: center;">
      <img
        src="https://kr.object.ncloudstorage.com/candiy-bucket/assets/banner/carousel_slide1.png"
        alt="캔디"
        style="height: 100%;"
      />
    </div>
    <!--    <p><a href="https://candiy.slack.com" target="_blank">Join CANDiY slack</a></p>-->

    <!--    <p>If you don't find answers to your questions about CANDiY, the best way is to use <a href="https://forum.solidproject.org/t/candiy-the-pod-in-the-pocket/3378" target="_blank">Solid Community Forum</a></p>-->
    <!--    <p>If you think CANDiY is what you need, you can share your opinion, impove & <a href="https://github.com/scenaristeur/solid-vue-panes/projects/1?add_cards_query=is%3Aopen" target="_blank">contribute</a> too.</p>-->

    <!--  <h3>Where is the source code of CANDiY?</h3>-->
    <!--    <p>Git repository at <a href="https://github.com/scenaristeur/solid-vue-panes/blob/master/README.md" target="_blank">https://github.com/scenaristeur/solid-vue-panes</a>.</p>-->

    <p class="lead">
      캔디(CANDiY) 서비스는 여러 곳에 흩어져 있는 여러분의 개인 데이터를 쉽고
      편리하게 모아 개인정보저장소(Personal Data Storage)에 안전하게 보관하고
      어느 앱에서나 보관된 데이터를 다시 활용할 수 있게 돕는 데이터 종합관리
      서비스입니다.
    </p>

    <div class="content">
      <div class="cont-body first">
        <div class="content-img">
          <img
            src="../../src/assets/content_1.png"
            alt="여기저기 흩어진 내 정보"
          />
        </div>
        <h3>여기저기 흩어진 내 정보</h3>
        <p>
          여러분의 개인 데이터는 여러 사이트/앱에 흩어져 보관되어 있습니다.
          여러분의 개인정보이지만 여러분이 관리하고 활용하기 어려웠습니다. 만약
          이사를 가게 되거나 휴대폰 번호를 바꾸게 된다면 여러분이 직접 각
          사이트에 방문해 개인정보를 변경해야 했습니다.
        </p>
        <p>
          또한 여러분의 개인정보를 보관한 기업이 사라지기라도 한다면…? 여러분의
          소중한 데이터들은 삭제될 수도 있습니다.
        </p>
      </div>

      <div class="cont-body second">
        <div class="content-img">
          <img
            src="../../src/assets/content_2.png"
            alt="캔디가 관리해드려요!"
          />
        </div>
        <h3>캔디가 관리해드려요!</h3>
        <p>
          캔디 개인정보저장소는 기업 중심이 아닌 여러분 스스로 데이터를
          통제/관리/활용할 수 있는 데이터 종합관리 기능을 보유한 개인데이터
          저장소입니다.
        </p>
        <ul>
          <li>
            <p>1</p>
            시간과 장소의 구애 없이 여러 곳에 흩어진 여러분의 데이터를 한꺼번에
            모아 저장하고 한눈에 확인할 수 있는 기능을 포함합니다.
          </li>
          <li>
            <p>2</p>
            개인이 스스로 저장된 데이터를 관리할 수 있는 기능이 포함되었습니다.
            보관 뿐 아니라 다른 기업에 나의 개인 데이터를 전달할 수 있는
            통제권한 까지 누릴 수 있습니다.
          </li>
          <li>
            <p>3</p>
            보관된 개인 데이터를 다른 서비스에 전달할 수 있게 되고, 나만을 위한
            서비스로 만들 수 있게 도와줍니다.
          </li>
        </ul>
      </div>

      <div class="cont-body third">
        <h3>이런분들께 유용해요</h3>
        <ul>
          <li>
            <p>
              다운로드 기간을 놓쳐 데이터를 잃어본 분
            </p>
            → 캔디 저장소에서 시간에 공간의 구애없이 내가 원하는 데이터를
            저장하고 확인 할 수 있습니다.
          </li>
          <li>
            <p>
              여러 메신저를 사용해 데이터가 흩어져있는 분
            </p>
            → 캔디 저장소에서 각 메신저들의 데이터를 모아 안전하게 보관하고,
            K메신저에서 사용하던 정보를 L메신저에 옮겨서 사용할 수도 있습니다.
          </li>
          <li>
            <p>
              SNS에 올린 추억을 안전하게 간직하고 싶은 분
            </p>
            → 캔디 저장소에서는 서비스가 사라져도 개인 데이터를 안전하게
            저장하고 재사용 가능 할 수 있도록 도와줍니다.
          </li>
        </ul>
      </div>
    </div>

    <!--    <h3>Components</h3>-->
    <!--    CANDiY is developped around the concept of webcomponents.-->
    <!--    <ul>-->
    <!--      <li>SoligLogin (example of code with the source <a href="https://github.com/scenaristeur/solid-vue-panes/blob/master/src/components/solid/SolidLogin.vue" target="_blank">SolidLogin.vue</a> )</li>-->
    <!--    </ul>-->
    <div v-if="webId != null">
      <b-button
        to="/editor"
        style="background-color: #5EBDEB;outline:none; border:none; width:100%; font-size: 18px; font-weight: 600;;"
        >캔디와 연결하기</b-button
      >
    </div>
    <div v-else>
      <div class="solidintro">
        나만의 안전한 솔리드 저장소를 시작하세요.
      </div>
      <SolidLoginButton />
    </div>
  </div>
</template>
<script>
import loginMixin from "@/mixins/loginMixin";

export default {
  //  store,

  name: "Storage",
  components: {
    SolidLoginButton: () => import("@/components/solid/SolidLoginButton"),
  },
  mixins: [loginMixin],
  data: function() {
    return {};
  },
  created() {
    // if (this.webId == null) {
    //   this.popupLogin();
    // }
    //  this.solid= window.solid
    //  this.webId =
    //  fc = new SolidFileClient(auth)
    //  example     await solid.data.from(this.fileUrl)[index]['http://www.w3.org/2005/01/wf/flow#message'].set(namedNode(messUri))
  },
  methods: {},
  computed: {
    webId() {
      return this.$store.state.solid.webId;
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
p {
  margin: 0;
  padding: 0;
}
.about {
  text-align: left;
  margin-top: 10px;
  width: 100%;
  padding: 0 25px;
  margin-bottom: 50px;
}
.title-box {
  margin: 0 auto;
  height: 6.9444vw;
  position: relative;
  width: 100%;
}
.title-box a {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 4.7222vw;
  color: #007bff;
  font-weight: 400;
}
.title-box a svg {
}
.page-title {
  font-size: 24px;
  font-weight: 600;
  color: #333;
  margin: 30px 0 15px;
}
.lead {
  margin: 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.4;
  color: #666;
  word-break: keep-all;
  margin-bottom: 40px;
}
.content {
  margin: auto;
}
.content .cont-body {
  margin-bottom: 30px;
  background: #fff;
  box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.1);
  padding: 30px;
  border-radius: 10px;
}
.content .cont-body h3 {
  font-size: 20px;
  font-weight: 600;
  color: #333;
  margin-bottom: 10px;
}
.content .cont-body p {
  font-size: 16px;
  font-weight: 400;
  color: #666;
  word-break: keep-all;
}
.content .cont-body ul {
  padding-inline-start: 0;
  list-style: none;
  margin: 0;
}
.content .cont-body ul li {
  font-size: 16px;
  color: #666;
  font-weight: 400;
  word-break: keep-all;
  margin-bottom: 8px;
  padding-top: 5px;
}
.content .cont-body ul li:last-child {
  margin-bottom: 0;
}
.content .cont-body.first {
}
.content .first h3 + p {
  margin-bottom: 10px;
}
.content .cont-body .content-img {
  width: 100%;
  height: 230px;
  text-align: center;
  margin-bottom: 30px;
}
.content .cont-body .content-img img {
  height: 100%;
}
.content .cont-body.second {
}
.content .second h3 + p {
  margin-bottom: 10px;
}
.content .cont-body.second ul li {
  text-align: center;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 20px;
}
.content .cont-body.second ul li:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
.content .cont-body.second ul li p {
  font-weight: 900;
  color: #5ebdeb;
  font-size: 20px;
  text-align: center;
  margin-bottom: 5px;
}
.content .cont-body.third {
}
.content .cont-body.third h3 {
  margin-bottom: 20px;
}
.content .cont-body.third ul li p {
  font-weight: 900;
  color: #fb5c5c;
  font-size: 18px;
  margin-bottom: 5px;
}
.solidintro {
  margin-bottom: 10px;
  font-size: 20px;
  text-align: center;
  font-weight: 600;
  word-break: keep-all;
}
</style>
